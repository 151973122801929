import Axios from "axios";
import { url } from "../../global";

const saveKanban = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_KANBAN',
            payload: Axios.post(`${url}/kanban`, {
                rack_id: data.rack_id,
                rack_name: data.rack_name,
                parts: data.parts
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

export { saveKanban }