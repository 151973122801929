import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName } from '../global';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { updateCycle, getCycle } from '../store/actions/cycleAction';
import {connect} from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
class EditCycle extends React.Component {

    state = {
        code: '',
        name: '',
    };
    
    handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
    }
    
    handleChangeSelect = name => (value) => {
        this.setState({
			...this.state,
            [`${name}_id`]: value ? value.value : null,
            [`${name}_name`]: value ? value.label : null,
		});
    }


	handleSubmit = (e) => {
		e.preventDefault();
        this.props.updateCycle(this.props.match.params.id, this.state);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.saved !== this.props.saved) {

            const { toastManager } = this.props;
            toastManager.add(this.props.message, {
                appearance: 'success',
                autoDismiss: true
            });

            this.props.history.push('/cycle');
        }

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        }
    }

    componentWillUpdate = (nextProps) => {
        if (nextProps !== this.props) {
            if (nextProps.data) {
                this.setState({
                    ...this.state,
                    id: nextProps.data._id ? nextProps.data._id : '',
                    code: nextProps.data.code ? nextProps.data.code : '',
                    name: nextProps.data.name ? nextProps.data.name : '',
                })
            }
        }
    }


    componentDidMount = () => {
        this.props.getCycle(this.props.match.params.id)
    }
    
	render() {      
        const { fetching, error } = this.props;
        const {
            code,
            name
        } = this.state;
        if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Edit Cycle | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-8">
					    <PageTitle sm="4" title="Edit Cycle" className="text-sm-left" />
                    </div>
                    <div className="col-md-4 text-right">
                         <Link className="btn btn-secondary" to="/cycle">Back</Link>
                    </div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label className="control-label">Code / ID <span className="text-danger">*</span></label>
                                                        <input value={code} type="text" id="code" className={`form-control ${ error && error.data.errors.code && 'is-invalid' }`} onChange={this.handleChange} placeholder="Cycle Code" />
                                                        { 
                                                            error && error.data.errors.code && <div className="invalid-feedback">{ error.data.errors.code[0] }</div>
                                                        }
                                                    </div>

                                                </div>

                                                <div className="col-md-6">

                                                    <div className="form-group">
                                                        <label className="control-label">Name<span className="text-danger">*</span></label>
                                                        <input value={name} type="text" id="name" className={`form-control ${ error && error.data.errors.name && 'is-invalid' }`} onChange={this.handleChange} placeholder="Cycle Name" />
                                                        { 
                                                            error && error.data.errors.name && <div className="invalid-feedback">{ error.data.errors.name[0] }</div>
                                                        }
                                                    </div>

                                                </div>
                                                <div className="col-md-12 text-right">
                                                    <hr/>
                                                    {
                                                        this.props.fetching ? (
                                                            <button className="btn btn-secondary btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                        ) : (
                                                            <button className="btn btn-secondary" type="submit">Save Changes</button>
                                                        )
                                                    }
                                                    <button className="btn btn-default" type="reset">Reset</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}


const mapStateToProps = (state) => {
    return {
        ...state,
        saved: state.cycle.saved,
        fetching: state.cycle.fetching,
        fetched: state.cycle.fetched,
        message: state.cycle.message,
        error: state.cycle.error,
        data: state.cycle.cycle.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateCycle: (id, data) => dispatch(updateCycle(id, data)),
        getCycle: id => dispatch(getCycle(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(EditCycle));
