import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, url } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import Axios from 'axios';
import { connect } from 'react-redux';
import { saveKanban } from '../store/actions/kanbanActions';
import QrReader from 'react-qr-reader'
import ScrollToTop from '../components/layout/ScrollToTop';

class Kanban extends React.Component {

    state = {
        rack: '',
        kanban: '',
        kanban_dn: '',
        disabled: false,
        kanban_dn_disable: false,
        error: null,
        rack_id: '',
        parts: [],
        qrmodal: false,
        qrmodalrack: false,
        qrmodaldn: false
    };

    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.id]: e.target.value
        })
    }

    handleKeyDownRack = (e) => {
        if (e.key === 'Enter') {
            this.checkRack(e.target.value)
        }
    }

    checkRack = (rack) => {
        Axios.get(`${url}/kanban/check/${rack}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {

            this.setState({
                ...this.state,
                disabled: true,
                rack_id: res.data.data._id,
                error: null
            });

            this.kanbanDnInput.focus();

        }).catch(error => {

            this.setState({
                ...this.state,
                error: error.response,
                rack: ''
            });

        })
    }

    handleRemovePart = (index) => {
        let parts = this.state.parts
        parts.splice(index, 1)

        this.setState({
            ...this.state,
            parts
        })
    }

    handleKeyDownKanban = (e) => {
        if (e.key === 'Enter') {
            this.checkPart(e.target.value)
        }
    }

    checkPart = (kanban) => {
        Axios.get(`${url}/kanban/part`, {
            params: {
                kanban,
                dn: this.state.kanban_dn
            }, headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            
            let parts = []
            parts.push(res.data.data)

            this.setState({
                error: null,
                parts: parts,
                kanban: ''
            })

        }).catch(err => {
            
            this.setState({
                ...this.state,
                error: err.response,
                kanban: '',
                disabled: false
            });
        })
    }

    handleSave = () => {
        this.props.saveKanban(this.state)
    }

    handleReset = () => {
        this.setState({
            rack: '',
            kanban: '',
            kanban_dn: '',
            disabled: false,
            kanban_dn_disable: false,
            error: null,
            rack_id: '',
            parts: []
        });
    }

    handleScanPopup = () => {
        this.setState({
            ...this.state,
            qrmodal: true
        })
    }

    handleCloseModal = () => {
        this.setState({
            ...this.state,
            qrmodal: false
        })
    }

    handleScan = data => {
        if (data) {

            this.checkPart(data)
            
            this.setState({
                qrmodal: false
            })
        }
    }
    
    handleError = err => {
        console.error(err)
    }


    handleScanPopupRack = () => {
        this.setState({
            ...this.state,
            qrmodalrack: true
        })
    }

    handleCloseModalRack = () => {
        this.setState({
            ...this.state,
            qrmodalrack: false
        })
    }

    handleScanRack = data => {
        if (data) {

            this.checkRack(data)
            
            this.setState({
                rack: data,
                qrmodalrack: false
            })
        }
    }
    
    handleErrorRack = err => {
        console.error(err)
    }

    handleKeyDownDn = (e) => {
        if (e.key === 'Enter') {
            this.checkDn(e.target.value)
        }
    }

    handleScanPopupDn = () => {
        this.setState({
            ...this.state,
            qrmodaldn: true
        })
    }

    handleCloseModalDn = () => {
        this.setState({
            ...this.state,
            qrmodaldn: false
        })
    }

    handleScanDn = data => {
        if (data) {

            this.checkDn(data)
        }
    }
    
    handleErrorDn = err => {
        console.error(err)
    }


    checkDn = (id) => {

        Axios.get(`${url}/kanban/check-dn/${id}`, {

            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }

        }).then(res => {

            this.kanbanInput.focus();

            this.setState({
                kanban_dn: id,
                qrmodaldn: false,
                kanban_dn_disable: true,
                error: null,
            })

        }).catch(error => {

            this.setState({
                ...this.state,
                error: error.response,
                kanban_dn: ''
            });

        })

    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.fetching !== this.props.fetching) {
           
            if (this.props.saved) {
                const { toastManager } = this.props;
                toastManager.add(this.props.message, {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.handleReset()
            }
        
        }
    }
    
	render() {      
        const { fetching } = this.props;
        const {
            rack,
            kanban,
            kanban_dn,
            disabled,
            kanban_dn_disable,
            error,
            parts,
            qrmodal,
            qrmodalrack,
            qrmodaldn
        } = this.state;

        if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Scan Kanban | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-12">
					    <PageTitle sm="4" title="Scan Kanban" className="text-sm-left" />
                    </div>
				</Row>
				<Row>
                    {
                        qrmodal && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModal} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                                delay={300}
                                                onError={this.handleError}
                                                onScan={this.handleScan}
                                                style={{ width: '100%' }}
                                                // facingMode={cameramode}
                                            />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

                    {
                        qrmodalrack && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModalRack} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                                delay={300}
                                                onError={this.handleErrorRack}
                                                onScan={this.handleScanRack}
                                                style={{ width: '100%' }}
                                                // facingMode={cameramode}
                                            />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

                    {
                        qrmodaldn && (
                            <ScrollToTop>
                                <div className="messagebox msgbox-qr">
                                    <div className="modal-qr">
                                        <button onClick={this.handleCloseModalDn} className="btn btn-default btn-cicle btn-lg p-0 pull-right"><i className="mdi mdi-close"></i></button>
                                        <QrReader
                                                delay={300}
                                                onError={this.handleErrorDn}
                                                onScan={this.handleScanDn}
                                                style={{ width: '100%' }}
                                                // facingMode={cameramode}
                                            />
                                    </div>
                                </div>
                                <div className="backdrop"></div>
                            </ScrollToTop>
                        )
                    }

					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="control-label">Rack<span className="text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input autoFocus onKeyDown={this.handleKeyDownRack} value={rack} type="text" id="rack" className={`form-control form-control-lg ${ error && error.data.errors.rack && 'is-invalid' }`} onChange={this.handleChange} placeholder="Rack" readOnly={disabled ? true : false} />
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary" onClick={this.handleScanPopupRack} type="button" id="button-addon1"><i className="mdi mdi-camera mdi-24px"></i></button>
                                                        </div>
                                                        { 
                                                            error && error.data.errors.rack && <div className="invalid-feedback">{ error.data.errors.rack[0] }</div>
                                                        }
                                                    </div>
                                                </div>

                                                <hr />
                                                
                                                <div className="form-group">
                                                    <label className="control-label">Kanban DN<span className="text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input onKeyDown={this.handleKeyDownDn} value={kanban_dn} ref={(input) => { this.kanbanDnInput = input; }} type="text" id="kanban_dn" className={`form-control form-control-lg ${ error && error.data.errors.kanban_dn && 'is-invalid' }`} readOnly={kanban_dn_disable ? true : false} onChange={this.handleChange} placeholder="Kanban DN" />
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary" onClick={this.handleScanPopupDn} type="button" id="button-addon1"><i className="mdi mdi-camera mdi-24px"></i></button>
                                                        </div>
                                                        { 
                                                            error && error.data.errors.kanban_dn && <div className="invalid-feedback">{ error.data.errors.kanban_dn[0] }</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="control-label">Kanban<span className="text-danger">*</span></label>
                                                    <div className="input-group mb-3">
                                                        <input onKeyDown={this.handleKeyDownKanban} value={kanban} ref={(input) => { this.kanbanInput = input; }} type="text" id="kanban" className={`form-control form-control-lg ${ error && error.data.errors.kanban && 'is-invalid' }`} onChange={this.handleChange} placeholder="Kanban" />
                                                        <div className="input-group-prepend">
                                                            <button className="btn btn-outline-secondary" onClick={this.handleScanPopup} type="button" id="button-addon1"><i className="mdi mdi-camera mdi-24px"></i></button>
                                                        </div>
                                                        { 
                                                            error && error.data.errors.kanban && <div className="invalid-feedback">{ error.data.errors.kanban[0] }</div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className={`control-label ${error && error.data.errors.parts}`}>Parts</label>
                                                    { 
                                                        error && error.data.errors.parts && <div className="text-danger">{ error.data.errors.parts[0] }</div>
                                                    }
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Part Number</th>
                                                                <th>Part Name</th>
                                                                <th>Qty</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                parts && parts.length > 0 ? parts.map((part, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>
                                                                                { part.part_no }
                                                                            </td>
                                                                            <td>
                                                                                { part.part_name }
                                                                            </td>
                                                                            <td>
                                                                                { part.qty }
                                                                            </td>
                                                                            <td>
                                                                                <button onClick={(index) => this.handleRemovePart(index)} className="btn text-danger btn-link btn-sm"><i className="mdi mdi-close"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }) : (

                                                                    <tr>
                                                                        <td colSpan="4" className="text-center">No data</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </div>

                                            
                                            <div className="col-md-12 text-right">
                                                <hr/>
                                                {
                                                    this.props.fetching ? (
                                                        <button className="btn btn-secondary btn-disabled" type="submit" disabled><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                    ) : (
                                                        <button className="btn btn-secondary" type="submit" onClick={this.handleSave}>Save</button>
                                                    )
                                                }
                                                <button className="btn btn-default" type="reset" onClick={this.handleReset}>Reset</button>
                                            </div>
                                        </div>

                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}

const mapStateToProps = (state) => {
    return {
        ...state,
        saved: state.kanban.saved,
        fetching: state.kanban.fetching,
        fetched: state.kanban.fetched,
        message: state.kanban.message,
        error: state.kanban.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        saveKanban: data => dispatch(saveKanban(data))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(Kanban));
