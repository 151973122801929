import React from 'react';
import { Container, Row, Col, Card, CardBody, DatePicker } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, customerStyles, url } from '../global';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import { updateQrcode, getQrcode } from '../store/actions/qrcodeAction';
import {connect} from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import AsyncSelect from 'react-select/async';
import Axios from 'axios';
import NumberFormat from 'react-number-format';
import Error403 from './Error403';

class EditQr extends React.Component {

    state = {
        _id: '',
        part_number: '',
        description: '',
        supplier_id: '',
        supplier_name: '',
        qty: '',
        lot_id: '',
        type_part: '',
        coil_id: '',
        plant_id: '',
        packing_date: '',
        copies: ''
    };

    handleChangeDate = (e) => {
        this.setState({
            ...this.state,
            packing_date: new Date(e)
        })
    }
    
    handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmit = (e) => {
		e.preventDefault();
        this.props.updateQrcode(this.props.match.params.id, this.state);
    }

    handleChangeSelect = (value, e) => {
        this.setState({
			...this.state,
            [`${e.name}_id`]: value ? value.value : null,
            [`${e.name}_name`]: value ? value.label : null,
		});
    }

    componentDidMount() {
        this.props.getQrcode(this.props.match.params.id);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.saved !== this.props.saved) {

            const { toastManager } = this.props;
            toastManager.add(this.props.message, {
                appearance: 'success',
                autoDismiss: true
            });

            this.props.history.push('/qr-code');
        }

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        }
    }

    componentWillUpdate = (nextProps) => {
        if (nextProps !== this.props) {
            if (nextProps.data) {
                this.setState({
                    ...this.state,
                    id: nextProps.data._id ? nextProps.data._id : '',
                    part_number: nextProps.data.part_number ? nextProps.data.part_number : '',
                    description: nextProps.data.description ? nextProps.data.description : '',
                    supplier_id: nextProps.data.supplier_id ? nextProps.data.supplier_id : '',
                    supplier_name: nextProps.data.supplier_name ? nextProps.data.supplier_name : '',
                    qty: nextProps.data.qty ? nextProps.data.qty : '',
                    lot_id: nextProps.data.lot_id ? nextProps.data.lot_id : '',
                    type_part: nextProps.data.type_part ? nextProps.data.type_part : '',
                    coil_id: nextProps.data.coil_id ? nextProps.data.coil_id : '',
                    plant_id: nextProps.data.plant_id ? nextProps.data.plant_id : '',
                    plant_name: nextProps.data.plant && nextProps.data.plant.code ? nextProps.data.plant.code : '',
                    packing_date: nextProps.data.packing_date ? new Date(nextProps.data.packing_date) : '',
                    copies: nextProps.data.copies ? nextProps.data.copies : ''
                })
            }
        }
    }
    
	render() {      
        const { fetching, error } = this.props;
        if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Edit Qr | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-8">
					    <PageTitle sm="4" title="Edit Qr" className="text-sm-left" />
                    </div>
                    <div className="col-md-4 text-right">
                         <Link className="btn btn-secondary" to="/qr-code">Back</Link>
                    </div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">

                                                <div className="col-md-6">

                                                    <div className="form-group">
                                                        <label className="control-label">Part Number<span className="text-danger">*</span></label>
                                                        <input value={this.state.part_number} type="text" id="part_number" className={`form-control ${ error && error.data.errors.part_number && 'is-invalid' }`} onChange={this.handleChange} placeholder="Part Number" />
                                                        { 
                                                            error && error.data.errors.part_number && <div className="invalid-feedback">{ error.data.errors.part_number[0] }</div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Part Name<span className="text-danger">*</span></label>
                                                        <input value={this.state.description} type="text" id="description" className={`form-control ${ error && error.data.errors.description && 'is-invalid' }`} onChange={this.handleChange} placeholder="Part Name" />
                                                        { 
                                                            error && error.data.errors.description && <div className="invalid-feedback">{ error.data.errors.description[0] }</div>
                                                        }
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="control-label">Supplier<span className="text-danger">*</span></label>
                                                        <AsyncSelect value={{ label: this.state.supplier_name, value: this.state.supplier_id }} isClearable={true} className={error && error.data.errors.supplier_id && 'is-invalid-select'} styles={customerStyles} loadOptions={supplierOptions} name="supplier" placeholder="Type to search ..." onChange={this.handleChangeSelect} />
                                                        { 
                                                            error && error.data.errors.supplier_id && <small className="font-weight-bold text-danger">{ error.data.errors.supplier_id[0] }</small>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Type Part<span className="text-danger">*</span></label>
                                                        <input value={this.state.type_part} type="text" id="type_part" className={`form-control ${ error && error.data.errors.type_part && 'is-invalid' }`} onChange={this.handleChange} placeholder="Type Part" />
                                                        { 
                                                            error && error.data.errors.type_part && <div className="invalid-feedback">{ error.data.errors.type_part[0] }</div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Coil ID<span className="text-danger">*</span></label>
                                                        <input value={this.state.coil_id} type="text" id="coil_id" className={`form-control ${ error && error.data.errors.coil_id && 'is-invalid' }`} onChange={this.handleChange} placeholder="Coil ID" maxLength={30} />
                                                        { 
                                                            error && error.data.errors.coil_id && <div className="invalid-feedback">{ error.data.errors.coil_id[0] }</div>
                                                        }
                                                    </div>

                                                </div>

                                                <div className="col-md-6">

                                                    <div className="form-group">
                                                        <label className="control-label">Qty<span className="text-danger">*</span></label>
                                                        <NumberFormat decimalSeparator="." thousandSeparator="," value={this.state.qty} type="text" id="qty" className={`form-control ${ error && error.data.errors.qty && 'is-invalid' }`} onChange={this.handleChange} placeholder="Qty" />
                                                        { 
                                                            error && error.data.errors.qty && <div className="invalid-feedback">{ error.data.errors.qty[0] }</div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Lot ID<span className="text-danger">*</span></label>
                                                        <input value={this.state.lot_id} type="text" id="lot_id" className={`form-control ${ error && error.data.errors.lot_id && 'is-invalid' }`} onChange={this.handleChange} placeholder="Lot ID" maxLength={30} />
                                                        { 
                                                            error && error.data.errors.lot_id && <div className="invalid-feedback">{ error.data.errors.lot_id[0] }</div>
                                                        }
                                                    </div>


                                                    <div className="form-group">
                                                        <label className="control-label">Plant<span className="text-danger">*</span></label>
                                                        <AsyncSelect value={{ label: this.state.plant_name, value: this.state.plant_id }} isClearable={true} className={error && error.data.errors.plant_id && 'is-invalid-select'} styles={customerStyles} loadOptions={plantOptions} name="plant" placeholder="Type to search ..." onChange={this.handleChangeSelect} />
                                                        { 
                                                            error && error.data.errors.plant_id && <small className="font-weight-bold text-danger">{ error.data.errors.plant_id[0] }</small>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Packing date<span className="text-danger">*</span></label>
                                                        <DatePicker 
                                                            className={`form-control ${ error && error.data.errors.packing_date && 'is-invalid' }`} 
                                                            onChange={ this.handleChangeDate } 
                                                            placeholderText="mm/dd/yyyy"
                                                            selected={ this.state.packing_date }
                                                            id="packing_date"
                                                        />
                                                        { 
                                                            error && error.data.errors.packing_date && <div className="invalid-feedback">{ error.data.errors.packing_date[0] }</div>
                                                        }
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Copies<span className="text-danger">*</span></label>
                                                        <input value={this.state.copies} type="number" id="copies" className={`form-control ${ error && error.data.errors.copies && 'is-invalid' }`} onChange={this.handleChange} placeholder="Copies" />
                                                        { 
                                                            error && error.data.errors.copies && <div className="invalid-feedback">{ error.data.errors.copies[0] }</div>
                                                        }
                                                    </div>

                                                </div>

                                                <div className="col-md-12 text-right">
                                                    <hr/>
                                                    {
                                                        this.props.fetching ? (
                                                            <button className="btn btn-secondary btn-disabled" disabled type="button"><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
                                                        ) : (

                                                            <button className="btn btn-secondary" type="submit">Save Changes</button>
                                                        )
                                                    }
                                                    <button className="btn btn-default" type="reset">Reset</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}

const filterSupplier = (suppliers) => {
    const options = suppliers.map(supplier => {
        return { label: supplier.name, value: supplier.id }
    })
 
    return options;
 };
   
const supplierOptions = (inputValue, callback) => {
     Axios.get(`${url}/supplier/list`, {
         params: {
             name: inputValue,
         }, 
         headers: {
             Authorization: `Bearer ${sessionStorage.getItem('token')}`
         }
     }).then(response => {
         callback(filterSupplier(response.data.data));
     });
}

const filterPlant = (plants) => {
    const options = plants.map(plant => {
        return { label: plant.name, value: plant.id }
    })
 
    return options;
 };
   
const plantOptions = (inputValue, callback) => {
     Axios.get(`${url}/plant/list`, {
         params: {
             name: inputValue,
         }, 
         headers: {
             Authorization: `Bearer ${sessionStorage.getItem('token')}`
         }
     }).then(response => {
         callback(filterPlant(response.data.data));
     });
}

const mapStateToProps = (state) => {
    return {
        ...state,
        saved: state.qrcode.saved,
        fetching: state.qrcode.fetching,
        fetched: state.qrcode.fetched,
        message: state.qrcode.message,
        error: state.qrcode.error,
        data: state.qrcode.qrcode.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateQrcode: (id, data) => dispatch(updateQrcode(id, data)),
        getQrcode: id => dispatch(getQrcode(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(EditQr));
