import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { appName, customerStyles, url, socketurl } from '../global';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import { withToastManager } from 'react-toast-notifications';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Error403 from './Error403';
import Axios from 'axios';
import socketIOClient from "socket.io-client";

class ScanWarehouse extends React.Component {

    state = {
        code: '',
        delivery_note_details: '',
        warehouse_name: null,
        warehouse_id: null,
        kanban_id: null,
        fetching: false,
        error: null,
        payload: {}
    };
    
    handleChange = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
    }
    
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.getKanban(this.state.delivery_note_details)
        }
    }

    getKanban = (delivery_note_details) => {
        Axios.get(`${url}/scan-dn/${delivery_note_details}`, {
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            // console.log(res)
            this.saveKanban(res.data.data._id)

            this.setState({
                ...this.state,
                payload: res.data.data,
                error: null,
                delivery_note_details: ''
            })

        }).catch(err => {
            console.log(err.response)
            this.setState({
                ...this.state,
                error: err.response,
                payload: {}
            })
        })
    }

    saveKanban = (id) => {
        Axios.post(`${url}/scan-dn`,{
                    delivery_note_details_id: id,
                }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
        }).then(res => {
            const { toastManager } = this.props;
            toastManager.add(res.data.message, {
                appearance: 'success',
                autoDismiss: true
            });

            const socket = socketIOClient(socketurl);
            socket.emit('update part')
            
        }).catch(err => {
            const { toastManager } = this.props;
            toastManager.add(err.response.data.message, {
                appearance: 'error',
                autoDismiss: true
            });
        })
    }

	render() {  
        const { delivery_note_details, error, fetching, payload } = this.state
        if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
        if (error && error.status === 500) return <Error500 message={error.data.message} />
        if (error && error.status === 403) return <Error403 message={error.data.message} />
        return (
         
			<Container fluid className="main-content-container px-4">
                <Loading
						show={fetching}
						color="blue"
						showSpinner={false}
						/>
				<Helmet>
					<title>Scan Kanban DN | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
                <div className="col-md-8">
					    <PageTitle sm="12" title="Scan Kanban DN" className="text-sm-left" />
                    </div>
				</Row>
				<Row>
					<Col>
						<Card small className="mb-4">
							    <CardBody className="p-0 pb-3">
                                    <div className="col-md-12 mt-3">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="" className="control-label">Kanban DN</label>
                                                    <input onKeyDown={this.handleKeyDown} value={delivery_note_details} onChange={this.handleChange} id="delivery_note_details" type="text" className={`form-control form-control-lg ${ error && error.data.errors.delivery_note_details_id && 'is-invalid '}`} placeholder="Scan Kanban DN here" />
                                                    { 
                                                        error && error.data.errors.delivery_note_details_id && <div className="invalid-feedback">{ error.data.errors.delivery_note_details_id[0] }</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4 mb-5">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Part #</th>
                                                    <th>Part Name</th>
                                                    <th>Qty/Kbn</th>
													<th>Total Kanban</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
													payload && payload.part ? 
													 <tr>
                                                            <td>{ payload.part.code }</td>
                                                            <td>{ payload.part.name }</td>
                                                            <td>{ payload.qty_box }</td>
                                                            <td>{ payload.total_kanban }</td>
                                                        </tr>
                                                     : (

                                                        <tr>
                                                            <td colSpan="4" className="text-center">No Data</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
            
		);
	}
}
 


export default withToastManager(ScanWarehouse);