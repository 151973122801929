import Axios from "axios";
import { url } from "../../global";
import moment from "moment";

const fetchQrcode = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'QRCODE',
            payload: Axios.get(`${url}/qr-code`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering,
                    printed: filter.printed
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const getQrcode = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_QRCODE',
            payload: Axios.get(`${url}/qr-code/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateQrcode = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_QRCODE',
            payload: Axios.put(`${url}/qr-code/${id}`, {
                part_number: data.part_number,
                description: data.description,
                supplier_id: data.supplier_id,
                supplier_name: data.supplier_name,
                qty: data.qty,
                lot_id: data.lot_id,
                type_part: data.type_part,
                coil_id: data.coil_id,
                plant_id: data.plant_id,
                packing_date: moment(data.packing_date).format('YYYY-MM-DD'),
                copies: data.copies
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteQrcode = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_QRCODE',
            payload: Axios.delete(`${url}/qr-code/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchQrcode, getQrcode, updateQrcode, deleteQrcode };