import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormCheckbox, FormRadio } from 'shards-react';
import PageTitle from '../components/common/PageTitle';
import { Redirect } from 'react-router-dom';
import { appName, url, urlb } from '../global';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../components/layout/ScrollToTop';
import { withToastManager } from 'react-toast-notifications';
import { fetchDn, deleteDn } from '../store/actions/dnAction';
import {connect} from 'react-redux';
import Loading from 'react-loading-bar';
import Error500 from './Error500';
import Table from '../components/table/Table';
import Modal from 'react-bootstrap4-modal';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Error403 from './Error403';

class DeliveryNote extends React.Component {
	state = {
        search: null,
        page: 1,
        perpage: 10,
		keyword: null,
		alert: true,
		alertMsgBox: false,
		deleteIdQrcode: null,
		showMsgBox: false,
		isDeleted: false,
		ordering: {
            type: 'part_number',
            sort: 'asc'
		},
		modal: false,
		file_import: null,
		import: 'Choose file...',
		importing: false,
		checked: [],
		download: false,
		modalPrint: false,
		template: '1',
		downloadLoading: false,
		printed: false,
		detailsLoading: false,
		details: [],
		detailsOpen: false
	}
	
    handleSorting = (e) => {
        const type = e.target.id;
        const sort = this.state.ordering.sort;
        this.setState({
			...this.state,
            ordering: {
                type: type,
                sort: sort === 'asc' ? 'desc' : 'asc'
            }
        });
    }

    handleChangeKeyword = (e) => {
		this.setState({
			...this.state,
			[e.target.id]: e.target.value
		});
	}

	handleSubmitKeyword = (e) => {
		e.preventDefault();
		this.props.fetchDn(this.state);
	}

	handleClickPage = (e) => {
        this.setState({
            ...this.state,
            page: e
        });
    }

    hanldeChangePage  = (e) => {
        this.setState({
            ...this.state,
            perpage: e.target.value
        });
    }
    
    handleClickDelete = (id) => {
		this.setState({
			...this.state,
			deleteIdQrcode: id,
			showMsgBox: true
		});
	}
	
	handleClickYes = () => {

		this.setState({
			...this.state,
			alertMsgBox: true,
			showMsgBox: false,
			isDeleted: true
		});

		this.props.deleteDn(this.state.deleteIdQrcode);
	}

	handleClickNo = () => {
		this.setState({
			...this.state,
			showMsgBox: false,
			deleteIdQrcode: null
		});
	}

	handlePopupPrint = () => {
		const filter = this.state.checked.map(item => { return item.isChecked })
		
		if (filter.includes(true)) {
			this.setState({
				...this.state,
				modalPrint: true
			})
		} else {
			const { toastManager } = this.props;
			toastManager.add('Please select at least one item!', {
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

    componentWillUpdate(nextProps, nextState) {
        if (this.state.page !== nextState.page) {
            this.props.fetchDn(nextState);
        }

        if (this.state.perpage !== nextState.perpage) {
            this.props.fetchDn(nextState);
		}
		
		if (this.state.ordering !== nextState.ordering) {
			this.props.fetchDn(nextState);
		}

		if (this.state.printed !== nextState.printed) {
			this.props.fetchDn(nextState);
		}
    }
    
    componentDidUpdate = (prevProps, prevState) => {

        if (prevProps.error !== this.props.error) {
            if (!this.props.fetched) {
                if (this.props.error) {
                    const { toastManager } = this.props;
                    toastManager.add(this.props.error.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
		}
		
		if (prevProps.isDeleted !== this.props.isDeleted) {
			if (this.props.isDeleted) {
				const { toastManager } = this.props;
				toastManager.add(this.props.message, {
					appearance: 'success',
					autoDismiss: true
				});
				this.props.fetchDn(this.state);
			}
		}

		if (prevProps.payload !== this.props.payload) {
			const data = this.props.payload.data && this.props.payload.data.data.map(item => {
				return {
					id: item._id,
					isChecked: false
				}
			})
			
			this.setState({
				...this.state,
				checked: data
			})

		}

	}
	
	handleModal = () => {
		this.setState({
			...this.state,
			modal: true
		})
	}

	modalBackdropClicked = () => {
		this.setState({
			...this.state,
			modal: false,
			modalPrint: false
		});
	}

    componentDidMount = () => {
		this.props.fetchDn(this.state)
	}

	handleChangeFileImport = (e) => {
		
		if (e.target.files.length > 0) {

			// const toastManager = this.props.toastManager;
			// const file_size = e.target.files[0].size;
			const file_name = e.target.files[0].name;
			// const file_type = e.target.files[0].type;
	
			// if (file_type !==  'application/wps-office.xls' && file_type !== 'application/wps-office.xlsx' && file_type !== 'text/csv') {
			// 	toastManager.add("file format is not supported", {
			// 		appearance: 'error',
			// 		autoDismiss: true
			// 	});
			// 	console.log(file_type);
			// 	e.preventDefault();
			
			// } else if (file_size >=  1000000) {
			// 	toastManager.add("file size too big", {
			// 		appearance: 'error',
			// 		autoDismiss: true
			// 	});
			// 	console.log(file_size);
			// 	e.preventDefault();
			
			// } else {

				const file = e.target.files[0];
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = (e) => {
					this.setState({
						...this.state,
						import_file: e.target.result,
						import: file_name
					})
				}
			// }
	
		}
	}

	handleImport = () => {
		const { toastManager } = this.props;
		
		this.setState({
			...this.state,
			importing: true
		});

		Axios.post(`${url}/delivery-note/import`, {
			import_file: this.state.import_file,
			import: this.state.import
		},
		{
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(res => {
			
			this.setState({
				...this.state,
				modal: false,
				importing: false,
				file_import: null,
				import: 'Choose file...'
			});

			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			});

			this.props.fetchDn(this.state);

		})
		.catch(error => {
			
			this.setState({
				...this.state,
				modal: false,
				importing: false,
				file_import: null,
				import: 'Choose file...'
			});

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});
		});
	}

	handleCheckBox = (event) => {
		
		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.value)
				checked.isChecked =  event.target.checked
		})

		this.setState({
			...this.state,
			checked: checked
		})

	}

	handleCopies = (event) => {

		const { toastManager } = this.props;

		Axios.put(`${url}/qr-code/${event.target.dataset.value}`, {
			copies: event.target.value
		}, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(res => {

			toastManager.add(res.data.message, {
				appearance: 'success',
				autoDismiss: true
			});

		}).catch(error => {

			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})

		let checked = this.state.checked

		checked.forEach(checked => {
			if (checked.id === event.target.dataset.value)
				checked.copies =  event.target.value
		})

		this.setState({
			...this.state,
			checked: checked
		})
	}

	handleCheckAll = () => {

		let checked = this.state.checked
    	checked.forEach(check => check.isChecked = true) 
		this.setState({checked: checked})
	
		
	}

	handleUnCheckAll = () => {
		let checked = this.state.checked
    	checked.forEach(check => check.isChecked = false) 
		this.setState({checked: checked})

	}

	handleToggleCheck = () => {
		
		let checked = this.state.checked

		checked.forEach(checked => {
			checked.isChecked =  checked.isChecked ? false : true
		})

		this.setState({checked: checked})
		
	}

	handleChangeTemplate = (e) => {

		this.setState({
			...this.state,
			template: e.target.value
		})
	}

	handlePrint = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			download: true
		});

		await Axios.post(`${url}/qr-code/print`, {
			check: this.state.checked,
			template: this.state.template
		}, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(response => {
			
			this.setState({
				...this.state,
				download: false
			});

			const file = new Blob(
				[response.data], 
				{type: 'application/pdf'});
			const fileURL = URL.createObjectURL(file);
			
			window.open(fileURL, "_blank");
			
			this.setState({
				...this.state,
				modalPrint: false
			});

			this.props.fetchDn(this.state);
		  
		}).catch(error => {

			this.setState({
				...this.state,
				download: false
			});


			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})
	}

	detailsTable = (id) => {
		
	
			return (
				<tr>
					<td colSpan="16" className="py-0">
						<div className="row">
							<div className="col-md-12">
								<table className="table table-bordered">
									<thead>
										<tr>
											<td>Part Number</td>
											<td>Name</td>
											<td>Qty</td>
											<td>Job Number</td>
											<td>Remark</td>
										</tr>
									</thead>
									<tbody>
										{
											this.state.detailsLoading ? (
												<tr>
													<td className="text-center" colSpan="5">
														<i className="mdi mdi-loading mdi-spin mr-2"></i>
														Loading ...
													</td>
												</tr>
											) : (
												this.state.details[id] ? this.state.details[id].map((detail, index) => {
													return (
														<tr key={index}>
															<td>{detail.material_number}</td>
															<td>{detail.material_name}</td>
															<td>{detail.qty_box * detail.total_kanban }</td>
															<td>{detail.job_number}</td>
															<td>{detail.remark}</td>
														</tr>
													)
												}) : (
													<tr>
														<td className="text-center" colSpan="5">
															Data not found
														</td>
													</tr>
												)
											)
										}
									</tbody>
								</table>
							</div>
						</div>
					</td>
				</tr>
			)
		
	}

	getDetails = async (id) => {

		this.setState({
			...this.state,
			detailsLoading: true,
			detailsOpen: {
				[id]: !this.state.detailsOpen[id] ? true : false
			}
		})

		await Axios.get(`${url}/delivery-note/details/${id}`, {
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(res => {
			this.setState({
				...this.state,
				detailsLoading: false,
				details: { 
					[id]: res.data.data
				}
			})
		})
	}

	handleDownloadTemplate = async () => {
		const { toastManager } = this.props;
		this.setState({
			...this.state,
			downloadLoading: true
		});

		await Axios.get(`${url}/delivery-note/download-template`, {
			responseType: 'blob',
			headers: {
				Authorization: `Bearer ${sessionStorage.getItem('token')}`
			}
		}).then(response => {
			
			this.setState({
				...this.state,
				downloadLoading: false
			});

			window.open(`${urlb}/storage/dn-template.xlsx`, "_blank");

		}).catch(error => {

			this.setState({
				...this.state,
				downloadLoading: false
			});


			toastManager.add(error.response.data.message, {
				appearance: 'error',
				autoDismiss: true
			});

		})
	}

	handleChangeTab = (value) => {
		this.setState({
			...this.state,
			printed: value
		})
	}

	render() {

		const {payload, error, fetching} = this.props;
		if (!sessionStorage.getItem('token')) return <Redirect to="/login" />
		if (error && error.status === 500) return <Error500 message={error.data.message} />
		if (error && error.status === 403) return <Error403 message={error.data.message} />

		const {ordering} = this.state;
        const theads = [
            {name:'dn_number', 'value': `Delivery Note #`, sortable: true},
			{name:'supplier_name', 'value': 'Vendor', sortable: true},
			{name:'order_date', 'value': 'Order date', sortable: true},
            {name:'lane_number', 'value': 'Lane Number', sortable: true},
            {name:'delivery_per_day', 'value': 'Delivery', sortable: true},
            {name:'shop', 'value': 'Shop', sortable: true},
            {name:'delivery_date', 'value': 'Delivery Date', sortable: true},
            {name:'cycle', 'value': 'Cycle', sortable: true},
            {name:'cycle_time', 'value': 'Cycle Time', sortable: true},
            {name:'plan_site', 'value': 'Plant', sortable: true},
            {name:'area_code', 'value': 'Area Code', sortable: true},
			{name: 'transporter', value: 'Transporter', sortable: true },
			{name:'group_route', 'value': 'Group Route', sortable: true},
			{name:'customer_name', 'value': 'Customer', sortable: true},
			{name:'min', 'value': 'Min', sortable: true},
			{name:'max', 'value': 'Max', sortable: true}
		];

		const dns = payload.data && payload.data.data.map((dn, index) => {
			const checked = this.state.checked[index]
			return (
				<Fragment key={dn._id}>
					<tr>
						<td>
							<FormCheckbox checked={checked && checked.isChecked ? true : false} value={dn._id} onChange={this.handleCheckBox}><span className="text-primary">
								<strong>{ dn.dn_number }</strong></span>
							</FormCheckbox>
							<p className="text-center">
								<button className="btn btn-link btn-sm text-info" type="button" onClick={() => this.getDetails(dn._id)}><i className={`mdi ${this.state.detailsOpen[dn._id] ? 'mdi-chevron-down' : 'mdi-chevron-right'} mr-2`}></i>Show Details</button>
							</p>
						</td>
						<td>{ dn.supplier && dn.supplier.name  }</td>
						<td>{ dn.order_date_formatted }</td>
						<td>{ dn.lane_number }</td>
						<td>{ dn.delivery_per_day }</td>
						<td>{ dn.shop } </td>
						<td>{ dn.delivery_date_formatted } </td>
						<td>{ dn.cycle } </td>
						<td>{ dn.cycle_time_formatted } </td>
						<td>{ dn.plant && dn.plant.code } </td>
						<td>{ dn.plant && dn.plant.name } </td>
						<td>{ dn.transporter } </td>
						<td>{ dn.group_route } </td>
						<td>{ dn.customer && dn.customer.name } </td>
						<td>{ dn.min } </td>
						<td>{ dn.max } </td>
					</tr>
					{
						this.state.detailsOpen[dn._id] && (
							this.detailsTable(dn._id)
						)
					}
				</Fragment>
            );
		});


		return (
			<Container fluid className="main-content-container px-4">
				<Loading
					show={fetching}
					color="blue"
					showSpinner={false}
					/>
				<Helmet>
					<title>Delivery Note | {appName} </title>
				</Helmet>
				<Row noGutters className="page-header py-4">
					<PageTitle sm="4" title="Delivery Note"  className="text-sm-left" />
				</Row>
					<Modal visible={this.state.modalPrint} onClickBackdrop={this.modalBackdropClicked}>
						<div className="modal-header">
							<h5 className="modal-title">Print Delivery Note</h5>
						</div>
						<div className="modal-body py-0 pt-2 px-4">
							<div className="row">
								<div className="form-group">
									<FormRadio onChange={this.handleChangeTemplate} name="template" value="1" checked={ this.state.template === '1' ? true : false}>Delivery Note</FormRadio>
									<FormRadio onChange={this.handleChangeTemplate} name="template" value="2" checked={ this.state.template === '2' ? true : false}>Kanban</FormRadio>
								</div>
							</div>
						</div>
						<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.download ? (
								<button className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Loading...
								</button>
							) : (
								<button className="btn btn-secondary" onClick={this.handlePrint}>
									<i className="mdi mdi-printer"></i> Print selected
								</button>
							)
						}
						</div>
					</Modal>


					<Modal visible={this.state.modal} onClickBackdrop={this.modalBackdropClicked}>
						<div className="modal-header">
							<h5 className="modal-title">Import dns</h5>
						</div>
						<div className="modal-body py-0 pt-2 px-4">
							<div className="row">
								<div className="custom-file">
									<input
										id="import"
										type="file"
										className="custom-file-input"
										onChange={this.handleChangeFileImport} 
										accept=".xlsx, .xls, .csv"
									/>
									<label
										className="custom-file-label"
										htmlFor="customFile2"
										id="placeholderCustomFile2"
									>
										{this.state.import}
									</label>
								</div>
								<div className="mb-3">
									{
										this.state.downloadLoading ? (
											<button className="btn text-success btn-disabled p-0 mx-2" disabled onClick={this.handleDownloadTemplate}><i className="mdi mdi-loading mdi-spin mr-2"></i>Loading...</button>
										) : (
											<button className="btn text-success p-0 my-2" onClick={this.handleDownloadTemplate}><i className="mdi mdi-download mr-2"></i>Download upload template</button>
										)
									}
									
									<div>
										<small>
											*) File format is xlsx, xlsx, or csv (semicolon separator ';')<br />
											*) Warning! existing data will be overwritted, be carefully! <br />
											*) Max file size 1MBs <br />
										</small>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
						<button type="button" className="btn btn-default" onClick={this.modalBackdropClicked}>
							Close
						</button>
						{
							this.state.importing ? (
								<button type="button" className="btn btn-secondary btn-disabled" disabled>
									<i className="mdi mdi-loading mdi-spin"></i> Processing...
								</button>
							) : (
								<button type="button" className="btn btn-secondary" onClick={this.handleImport}>
									Import
								</button>
							)
						}
						
						</div>
					</Modal>
				<Row>
					{
						this.state.showMsgBox &&
						(
							<ScrollToTop>
								<div className="messagebox">
									<p className="mb-5">Are you sure want to delete this data?</p>
									<button className="btn btn-secondary mr-4" onClick={this.handleClickYes}>Yes</button>
									<button className="btn btn-default" onClick={this.handleClickNo}>No Cancel</button>
								</div>
								<div className="backdrop"></div>
							</ScrollToTop>
						)
					}
					<Col>
					<Card small className="mb-4">
							<CardBody className="p-0 pb-3">
						<div className="col-md-12 mt-4">
							<div className="row">
								<div className="col-md-8">
									<button className="btn btn-secondary" onClick={this.handleModal}>
										<i className="mdi mdi-upload" /> Import
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handleCheckAll}>
										<i className="mdi mdi-check-box-outline" /> Check all
									</button>
									<button className="btn btn-secondary" onClick={this.handleUnCheckAll}>
										<i className="mdi mdi-checkbox-blank-outline" /> Uncheck all
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handleToggleCheck}>
										<i className="mdi mdi-toggle-switch" /> Toggle Check
									</button>
									<button className="btn btn-secondary mx-2" onClick={this.handlePopupPrint}>
										<i className="mdi mdi-printer" /> Print selected
									</button>
									
								</div>
								<div className="col-md-4 text-right">
									<form onSubmit={this.handleSubmitKeyword}>
										<div className="input-group mb-3">
											<input
												id="keyword"
												type="text"
												className="form-control"
												placeholder=""
												aria-label="Example text with button addon"
												aria-describedby="button-addon1"
												onChange={this.handleChangeKeyword}
											/>
											<div className="input-group-prepend">
												<button
													className="btn btn-secondary"
													type="submit"
													id="button-addon1"
												>
													<i className="mdi mdi-magnify" /> Search{' '}
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-md-12 mt-3">

						
                            <Table theads={theads} ordering={ordering} handleSorting={this.handleSorting}>
                                { 
                                    fetching ? 
                                    (
                                        <tr>
                                            <td className="text-center" colSpan="16">Loading...</td>
                                        </tr>
                                    )
                                    :
                                    payload.data && payload.data.data.length > 0 ? dns : (
                                        <tr>
                                            <td className="text-center" colSpan="16">Data not found</td>
                                        </tr>
                                ) }
                            </Table>	
							
	
                        </div>
						<div className="col-md-12 py-3">
							<div className="row">
								<div className="col-md-10">
									{ payload.data && payload.data.total > 1 && (
										<p>Showing { payload.data && payload.data.from.toLocaleString() } to { payload.data && payload.data.to.toLocaleString() } of { payload.data && payload.data.total.toLocaleString() } record(s)</p>

									)}

									{
										payload.data && payload.data.total > 1 && (
											<nav aria-label="Page navigation example">
												<ul className="pagination">

													{ payload.data.current_page > 1 && <li key="prev" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page - 1)} className="page-link">Prev</button></li> }

													{
														payload.data.pages.map((page, index) => {
															return (
																
																<li key={index} className={`page-item ${page === '...' ? 'disabled' : '' } ${page === payload.data.current_page ? 'active' : '' }`}><button onClick={this.handleClickPage.bind(null, page)} className="page-link">{page}</button></li>
																
															)
														})
													}

													{ payload.data.current_page < payload.data.last_page && <li key="next" className="page-item"><button onClick={this.handleClickPage.bind(null, payload.data.current_page + 1)} className="page-link">Next</button></li> }


												</ul>
											</nav>
										)
									}
								</div>
								<div className="col-md-2 text-right">
									<div className="form-group">
										<label className="control-label">Showing per page </label>
										<select
											defaultValue="10"
											id="perpage"
											className="form-control custom-select"
											onChange={this.hanldeChangePage}
										>
											<option value="10">10</option>
											<option value="20">20</option>
											<option value="50">50</option>
											<option value="100">100</option>
										</select>
									</div>
								</div>
							</div>
						</div>
					</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}



const mapStateToProps = (state) => {
    return {
        ...state,
        payload: state.dn.payload,
        error: state.dn.error,
		fetching: state.dn.fetching,
		message: state.dn.message,
		saved: state.dn.saved,
		isDeleted: state.dn.isDeleted
    }
}

const mapDispatchToProps = (dispatch) => {
	return {
		fetchDn: (filter) => dispatch(fetchDn(filter)),
		deleteDn: (id) => dispatch(deleteDn(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withToastManager(DeliveryNote));
