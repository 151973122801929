import { combineReducers } from "redux";
import authReducer from "./authReducer";
import qrcodeReducer from "./qrcodeReducer";
import supplierReducer from "./supplierReducer";
import passwordReducer from "./passwordReducer";
import roleReducer from "./roleReducer";
import permissionReducer from "./permissionReducer";
import userReducer from "./userReducer";
import plantReducer from "./plantReducer";
import menuReducer from "./menuReducer";
import customerReducer from "./customerReducer";
import partReducer from "./partReducer";
import warehouseReducer from "./warehouseReducer";
import rackReducer from "./rackReducer";
import cycleReducer from "./cycleReducer";
import expeditionReducer from "./expeditionReducer";
import scanReducer from "./scanReducer";
import stockReducer from "./stockReducer";
import kanbanReducer from "./kanbanReducer";
import dnReducer from "./dnReducer";


const rootReducer = combineReducers({
    auth: authReducer,
    qrcode: qrcodeReducer,
    supplier: supplierReducer,
    password: passwordReducer,
    role: roleReducer,
    permission: permissionReducer,
    user: userReducer,
    plant: plantReducer,
    menu: menuReducer,
    customer: customerReducer,
    part: partReducer,
    warehouse: warehouseReducer,
    rack: rackReducer,
    cycle: cycleReducer,
    expedition: expeditionReducer,
    scan: scanReducer,
    stock: stockReducer,
    kanban: kanbanReducer,
    dn: dnReducer
})

export default rootReducer;