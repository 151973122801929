import Axios from "axios";
import { url } from "../../global";

const fetchWarehouse = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'WAREHOUSE',
            payload: Axios.get(`${url}/warehouse`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const saveWarehouse = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_WAREHOUSE',
            payload: Axios.post(`${url}/warehouse`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const getWarehouse = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_WAREHOUSE',
            payload: Axios.get(`${url}/warehouse/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateWarehouse = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_WAREHOUSE',
            payload: Axios.put(`${url}/warehouse/${id}`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteWarehouse = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_WAREHOUSE',
            payload: Axios.delete(`${url}/warehouse/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchWarehouse, saveWarehouse, getWarehouse, updateWarehouse, deleteWarehouse };