import Axios from "axios";
import { url } from "../../global";

const fetchPart = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'PART',
            payload: Axios.get(`${url}/part`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const savePart = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_PART',
            payload: Axios.post(`${url}/part`, {
                code: data.code,
                name: data.name,
                stock: data.stock
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const getPart = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_PART',
            payload: Axios.get(`${url}/part/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const updatePart = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PART',
            payload: Axios.put(`${url}/part/${id}`, {
                code: data.code,
                name: data.name,
                stock: data.stock
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}


const deletePart = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_PART',
            payload: Axios.delete(`${url}/part/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchPart, savePart, getPart, updatePart, deletePart };