import Axios from "axios";
import { url } from "../../global";

const fetchExpedition = (filter) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'EXPEDITION',
            payload: Axios.get(`${url}/expedition`, {
                params: {
                    page: filter.page,
                    perpage: filter.perpage,
                    keyword: filter.keyword,
                    ordering: filter.ordering
                },
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const saveExpedition = (data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'SAVE_EXPEDITION',
            payload: Axios.post(`${url}/expedition`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const getExpedition = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'GET_EXPEDITION',
            payload: Axios.get(`${url}/expedition/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

const updateExpedition = (id, data) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_EXPEDITION',
            payload: Axios.put(`${url}/expedition/${id}`, {
                code: data.code,
                name: data.name
            }, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}


const deleteExpedition = (id) => {
    return (dispatch, getState) => {
        dispatch({
            type: 'DELETE_EXPEDITION',
            payload: Axios.delete(`${url}/expedition/${id}`, {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            })
        })
    }
}

export { fetchExpedition, saveExpedition, getExpedition, updateExpedition, deleteExpedition };